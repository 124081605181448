<template>
  <div class="asset-frame-g">
    <h2>{{source.title}}</h2>
    <div class="charts">
      <div class="image" v-for="(img, idx) in source.src" :key="idx" @mouseenter="addMask" @mouseleave="removeMask" @click="preview(img)">
        <img :src="`${vendorURL}vendors/${vendorName}/image/small${img}`">
      </div>
    </div>
    <preview-theater v-if="isPreview" :scrollable="true">
      <img :src="`${vendorURL}vendors/${vendorName}/image/${openedImg}`">
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: ""
    };
  },
  methods: {
    preview(img) {
      this.openedImg = img;
      this.isPreview = true;
    },
    stopPreview() {
      this.isPreview = false;
    },
    addMask() {
      const tar = event.target;
      $(tar).addClass("clickMe");
    },
    removeMask() {
      const tar = event.target;
      $(tar).removeClass("clickMe");
    }
  }
};
</script>

<style lang="scss" scoped>
.asset-frame-g {
  margin: 4% 0;
  width: 100%;
  h2 {
    margin: 4% 0;
  }
  img {
    cursor: pointer;
  }
  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .image {
    position: relative;
    cursor: pointer;
  }
  .clickMe::before {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00000047;
    content: " ";
    position: absolute;
  }
}
</style>
