<template>
  <div class="asset-frame-b">
    <h2>{{source.title}}</h2>
    <div id="main-stage">
      <img class="rescale-b main" :src="`${vendorURL}vendors/${vendorName}/image/${source.src[0]}`" @click="preview(source.src[0])">
    </div>
    <div class="bottom-stage">
      <div v-for="(img, idx) in source.src.slice(1)" :key="idx" class="bottomStageItem" @mouseenter="addMask" @mouseleave="removeMask" @click="preview(img)">
        <img class="rescale-b" :src="`${vendorURL}vendors/${vendorName}/image/small${source.src[idx + 1]}`">
      </div>
    </div>
    <preview-theater v-if="isPreview">
      <img class="source" :src="`${vendorURL}vendors/${vendorName}/image/${openedImg}`">
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: ""
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    preview(img) {
      this.openedImg = img;
      this.isPreview = true;
    },
    stopPreview() {
      this.isPreview = false;
    },
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
    },
    addMask() {
      const tar = event.target;
      $(tar).addClass("clickMe");
    },
    removeMask() {
      const tar = event.target;
      $(tar).removeClass("clickMe");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.asset-frame-b {
  h2 {
    margin: 4% 0;
  }
  #main-stage img {
    width: 100%;
  }
  position: relative;
  img {
    cursor: pointer;
  }
  .bottom-stage {
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    img {
      width: 100%;
    }
  }
  .bottomStageItem {
    position: relative;
    cursor: pointer;
  }
  .clickMe::before {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00000047;
    content: " ";
    position: absolute;
  }
}
</style>
