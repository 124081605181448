<template>
  <div class="video-frame-b">
    <h2>動畫類</h2>
    <div class="display-stage">
      <img class="playBtn" src="../assets/image/caseStudies/playBtn.svg" @click="openTheater">
      <img :src="`${vendorURL}vendors/${vendorName}/vids/${currentVid[0]}`" @click="openTheater">
    </div>
    <div class="choose-stage slideStage">
      <carousel ref="carousel" :items="4" :nav="false" :margin="20">
        <img class="rescale vids" v-for="(img, idx) in source.src" :key="idx" @click="showVid(img, $event)"
        :class="{'not-picked': idx!=0}" :src="`${vendorURL}vendors/${vendorName}/vids/${img[0]}`">
        <template slot="prev">
          <span class="controls prev">
            <i class="fas fa-chevron-circle-left"></i>
          </span>
        </template>
        <template slot="next" v-if="source.src.length > 4">
          <span class="controls next">
            <i class="fas fa-chevron-circle-right"></i>
          </span>
        </template>
      </carousel>
    </div>
    <transition name="opaFadeIn">
    <preview-theater v-if="isPreview">
      <!-- <i v-if="!playing" class="fas fa-play-circle"></i> -->
      <youtube v-if="isYoutube" :player-vars="playerVars" :video-id="currentVid[2]" width="100%" height="100%" />
      <iframe v-if="isGoogleDrive" :src="`https://drive.google.com/file/d/${currentVid[2]}/preview`" height="100%" width="100%"></iframe>
      <video v-if="isOrigin" class="source" :src="`${vendorURL}vendors/${vendorName}/vids/${currentVid[1]}`" autoplay controls="true"
        @click.stop="" type="video/mp4">
      </video>
    </preview-theater>
    </transition>

  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    carousel,
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      currentVid: this.source.src[0],
      isPreview: false,
      playing: false,
      isYoutube: false,
      isGoogleDrive: false,
      isOrigin: false,
      playerVars: {
        autoplay: 1
      }
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
    },
    showVid(vid, e) {
      $(".vids").addClass("not-picked");
      this.isPreview = false;
      this.isYoutube = vid[1] == "YT"? true : false;
      this.isGoogleDrive = vid[1] == "GD"? true : false;
      this.isOrigin = (vid[1] != "YT" && vid[1] != "GD")? true : false;
      this.currentVid = vid;
      e.target.classList.remove("not-picked");
    },
    openTheater() {
      this.isPreview = true;
    },
  },
  watch: {
    isPreview() {
      if (this.isPreview) {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("html")[0].style.overflow = null;
        document.getElementsByTagName("html")[0].style.overflowX = "hidden";
      }
    }
  },
  mounted() {
    const firstVid = this.source.src[0][1];
    if (firstVid == "YT") {
      this.isYoutube = true;
    }  else if (firstVid == "GD") {
      this.isGoogleDrive = true;
    } else {
      this.isOrigin = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.fas {
  cursor: pointer;
}
.video-frame-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  img {
    cursor: pointer;
  }
  h2 {
    margin: 4% 0;
  }
  .display-stage {
    position: relative;
    width: 60%;
    .playBtn {
      color: #707070;
      position: absolute;
      width: 140px;
      height: 120px;
      top: calc(50% - 60px);
      left: calc(50% - 70px);
      z-index: 1;
    }
    img {
      width: 100%;
    }
  }
  .choose-stage {
    width: 75%;
    min-height: 80px;
    position: relative;
    #slideContent {
      display: flex;
      justify-content: space-between;
    }
    img {
      transition: 0.3s;
      width: 100%;
    }
    span {
      cursor: pointer;
    }
  }
  .theater {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 13;
    background-color: #000000cc;
    display: flex;
    align-items: center;
    justify-content: center;
    .fas {
      color: #fff;
      position: absolute;
      font-size: 60px;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      z-index: 14;
    }
    .source {
      max-width: 80vw;
      max-height: 80vh;
      transition: 0.3s;
    }
  }
}

.not-picked {
  filter: brightness(0.5);
}

.controls {
  position: absolute;
  top: calc(50% - 25px);
  font-size: 50px;
  @media #{$tablet} {
    top: calc(50% - 40px);
  }
  @media #{$phone} {
    font-size: 30px;
    top: calc(50% - 30px);
  }
  &.prev {
    right: 100%;
  }
  &.next {
    left: 100%;
  }
}
</style>
