<template>
  <div class="asset-frame-h">
    <h2>{{source.title}}</h2>
    <div class="charts">
      <div class="image" v-for="(img, idx) in source.src" :key="idx">
        <img :src="`${vendorURL}vendors/${vendorName}/image/${img}`" @click="preview(img)">
      </div>
    </div>
    <preview-theater v-if="isPreview">
      <img :src="`${vendorURL}vendors/${vendorName}/image/${openedImg}`">
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: ""
    };
  },
  methods: {
    preview(img) {
      this.openedImg = img;
      this.isPreview = true;
    },
    stopPreview() {
      this.isPreview = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.asset-frame-h {
  width: 100%;
  h2 {
    margin: 4% 0;
  }
  img {
    cursor: pointer;
  }
  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      width: 45%;
      img {
        width: 100%;
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 10%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 10%;
        }
      }
    }
  }
}
</style>
