<template>
  <div v-html="pageSource" id="customPage">
    
  </div>
</template>



<script>
export default {
  props: {
    pageSource: String
  }
};
</script>