import { render, staticRenderFns } from "./assetsFrameE.vue?vue&type=template&id=219c0653&scoped=true&"
import script from "./assetsFrameE.vue?vue&type=script&lang=js&"
export * from "./assetsFrameE.vue?vue&type=script&lang=js&"
import style0 from "./assetsFrameE.vue?vue&type=style&index=0&id=219c0653&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219c0653",
  null
  
)

export default component.exports