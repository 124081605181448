<template>
  <div class="assset-frame-sm">
    <h2>{{source.title}}</h2>
    <div class="computer">
      <img :src="`${vendorURL}plainComputer.png`">
      <img class="innerImg" :src="`${vendorURL}vendors/${vendorName}/image/${source.src}`">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: ""
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.assset-frame-sm {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 4% 0;
  }
  .computer {
    position: relative;
    width: 50%;
    @media #{$tablet} {
      width: 100%;
    }
    img {
      width: 100%;
    }
    .innerImg {
      position: absolute;
      width: 91.8%;
      height: 63%;
      left: 4.4%;
      top: 4.6%;
    }
  }
}
</style>
