<template>
  <div class="asset-frame-i">
    <h2></h2>
    <div class="upper-charts">
      <img :src="`${vendorURL}vendors/${vendorName}/image/${source.src[0]}`" @click="preview(source.src[0])">
    </div>
    <div class="bottom-charts">
      <div v-for="(img, idx) in source.src.slice(1)" :key="idx">
        <img :src="`${vendorURL}vendors/${vendorName}/image/${img}`" @click="preview(img)">
      </div>
    </div>
    <preview-theater v-if="isPreview">
      <img class="source" :src="`${vendorURL}vendors/${vendorName}/image/${openedImg}`">
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: ""
    };
  },
  methods: {
    preview(img) {
      this.openedImg = img;
      this.isPreview = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.asset-frame-i {
  margin-top: 8%;
  img {
    cursor: pointer;
  }
  .upper-charts {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .bottom-charts {
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    div {
      width: 40%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
