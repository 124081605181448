<template>
  <div id="case-view">
    <div id="back-to-top" @click="scrollToTop">
      <i class="fas fa-angle-up"></i>
    </div>
    <h2 class="light">{{vendorData.registerName}}</h2>
    <hr>
    <h3 class="light">CASE STUDY</h3>
    <div class="caseType">
      <template v-for="(caseType, idx) in caseTypes" >
      <div class="typeIcon" :key="idx">
        <img 
        @click="routerBlank(caseType.direct)"
        :src="`${baseUrl}static/caseType/${caseType.type}.svg`" 
        :title="caseType.title"
        :class="{'active': compareType(vendorData.caseType).includes(caseType.type)}">
        <span class="caseTitle" >{{caseType.title}}</span>
      </div>
      </template>
    </div>
    <div class="brandStory">
      <div class="brandImage">
        <img :src="`${vendorURL}vendors/${vendorData.name}/image/${vendorData.contents.brandStoryImg}`">
      </div>
      <div class="story">
        <h2>品牌故事</h2>
        <span>{{vendorData.contents.brandStory}}</span>
      </div>
    </div>
    <template v-if="!vendorData.contents.customPage">

      <template v-if="vendorData.contents.articleFrameSrc">
        <template v-if="vendorData.contents.articleFrameSrc.B">
          <article-frame-b v-for="(src, idx) in vendorData.contents.articleFrameSrc.B" :key="idx + 'B'"
            :vendorName="vendorData.name" :source="src"
          ></article-frame-b>
        </template>
        <template v-if="vendorData.contents.articleFrameSrc.C">
          <article-frame-c v-for="(src, idx) in vendorData.contents.articleFrameSrc.C" :key="idx + 'C'"
            :vendorName="vendorData.name" :source="src"
          ></article-frame-c>
        </template>
        <template v-if="vendorData.contents.articleFrameSrc.D">
          <article-frame-d v-for="(src, idx) in vendorData.contents.articleFrameSrc.D" :key="idx + 'D'"
            :vendorName="vendorData.name" :source="src"
          ></article-frame-d>
        </template>
      </template>
      <template v-if="vendorData.contents.assetsFrameSrc">
        <template v-if=" vendorData.contents.assetsFrameSrc.A" >
          <assets-frame-a v-for="(src, idx) in vendorData.contents.assetsFrameSrc.A" :key="idx + 'aA'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-a>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.B" >
          <assets-frame-b v-for="(src, idx) in vendorData.contents.assetsFrameSrc.B" :key="idx + 'aB'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-b>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.C" >
          <assets-frame-c v-for="(src, idx) in vendorData.contents.assetsFrameSrc.C" :key="idx + 'aC'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-c>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.D" >
          <assets-frame-d v-for="(src, idx) in vendorData.contents.assetsFrameSrc.D" :key="idx + 'aD'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-d>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.E" >
          <assets-frame-e v-for="(src, idx) in vendorData.contents.assetsFrameSrc.E" :key="idx + 'aE'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-e>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.F" >
          <assets-frame-f v-for="(src, idx) in vendorData.contents.assetsFrameSrc.F" :key="idx + 'aF'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-f>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.G" >
          <assets-frame-g v-for="(src, idx) in vendorData.contents.assetsFrameSrc.G" :key="idx + 'aG'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-g>
        </template>
        <template v-if=" vendorData.contents.assetsFrameSrc.H" >
          <assets-frame-h v-for="(src, idx) in vendorData.contents.assetsFrameSrc.H" :key="idx + 'aH'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-h>
        </template>
        <template v-if="vendorData.contents.assetsFrameSrc.I" >
          <assets-frame-i v-for="(src, idx) in vendorData.contents.assetsFrameSrc.I" :key="idx + 'aI'"
            :vendorName="vendorData.name" :source="src"
          ></assets-frame-i>
        </template>
      </template>

      <template v-if="vendorData.contents.videoFrameSrc">
        <template v-if="vendorData.contents.videoFrameSrc.A" >
          <video-frame-a v-for="(src, idx) in vendorData.contents.videoFrameSrc.A" :key="idx + 'vA'"
            :vendorName="vendorData.name" :source="src"
          ></video-frame-a>
        </template>
        <template v-if="vendorData.contents.videoFrameSrc.B" >
          <video-frame-b v-for="(src, idx) in vendorData.contents.videoFrameSrc.B" :key="idx + 'vB'"
            :vendorName="vendorData.name" :source="src"
          ></video-frame-b>
        </template>
        <template v-if="vendorData.contents.videoFrameSrc.C" >
          <video-frame-c v-for="(src, idx) in vendorData.contents.videoFrameSrc.C" :key="idx + 'vC'"
            :vendorName="vendorData.name" :source="src"
          ></video-frame-c>
        </template>
      </template>

      <data-grow v-if="vendorData.contents.dataGrow" :source="vendorData.contents.dataGrowData"/>

      <template v-if="vendorData.contents.assetsFrameSrc && vendorData.contents.assetsFrameSrc.SM">
        <assets-frame-sm v-for="(src, idx) in vendorData.contents.assetsFrameSrc.SM" :key="idx + 'sm'"
          :vendorName="vendorData.name" :source="src"
        ></assets-frame-sm>
      </template>
    </template>


    <template v-if="vendorData.contents.customPage">
        <!-- 此區為客製化網頁放置區 -->
      <custom-page :pageSource="vendorData.contents.pageSource" />
    </template>

    <template v-if="vendorData.similar.length != 0">
      <hr style="margin-top: 8%">
      <h2>相近案例</h2>
      <div class="vendors">
        <template v-for="(vendor, idx) in vendorData.similar.slice(0, 3)">
          <vendor-logos
            :vendorData="vendor"
            :key="idx"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import articleFrameB from "@/caseComponents/articleFrameB.vue";
import articleFrameC from "@/caseComponents/articleFrameC.vue";
import articleFrameD from "@/caseComponents/articleFrameD.vue";
import assetsFrameA from "@/caseComponents/assetsFrameA.vue";
import assetsFrameB from "@/caseComponents/assetsFrameB.vue";
import assetsFrameC from "@/caseComponents/assetsFrameC.vue";
import assetsFrameD from "@/caseComponents/assetsFrameD.vue";
import assetsFrameE from "@/caseComponents/assetsFrameE.vue";
import assetsFrameF from "@/caseComponents/assetsFrameF.vue";
import assetsFrameG from "@/caseComponents/assetsFrameG.vue";
import assetsFrameH from "@/caseComponents/assetsFrameH.vue";
import assetsFrameI from "@/caseComponents/assetsFrameI.vue";
import dataGrow from "@/caseComponents/dataGrow.vue";
import assetsFrameSm from "@/caseComponents/assetsFrameSm.vue";
import videoFrameA from "@/caseComponents/videoFrameA.vue";
import videoFrameB from "@/caseComponents/videoFrameB.vue";
import videoFrameC from "@/caseComponents/videoFrameC.vue";
import customPage from "@/caseComponents/customPage.vue";
import vendorLogos from "@/caseComponents/vendorLogos.vue";

export default {
  components: {
    articleFrameB,
    articleFrameC,
    articleFrameD,
    assetsFrameA,
    assetsFrameB,
    assetsFrameC,
    assetsFrameD,
    assetsFrameE,
    assetsFrameF,
    assetsFrameG,
    assetsFrameH,
    assetsFrameI,
    dataGrow,
    assetsFrameSm,
    videoFrameA,
    videoFrameB,
    videoFrameC,
    customPage,
    vendorLogos
  },
  props: {
    vendorName: String,
    vendorData: Object
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      baseUrl: process.env.BASE_URL,
      caseTypes:[
        {type: "website", title: "網站製作", direct: "website"},
        {type: "facebook", title: "FB 廣告投放", direct: "FBAds"},
        {type: "google", title: "google 廣告投放", direct: "GoogleAds"},
        {type: "line", title: "line 廣告投放", direct: "LineAds"},
        {type: "visualDesign", title: "視覺設計", direct: "visialDesign"},
        {type: "filming", title: "影片製作", direct: "filming"},
        {type: "socialMediaManagement", title: "自媒體經營", direct: "socialMediaManagement"},
        {type: "promote", title: "口碑宣傳", direct: "promoting"}
      ]
    };
  },
  methods: {
    showTitile(e) {
      this.hideTitle();
      e.target.nextElementSibling.classList.add("active");
    },
    hideTitle() {
      $(".caseTitle").removeClass("active");
    },
    scrollToTop() {
      $("html, body").animate({ scrollTop: 0 }, 1000);
    },
    routerBlank(route) {
      let target = this.$router.resolve({name: route});
      window.open(target.href, "_blank");
    },
    compareType(type) {
      var arr = new Array;
      type.forEach(element => {
        if(element == "productPromote" || element == "brandImage" || element == "interactive") {
          arr.push("website");
        } else if (element == "enterpriseBrand" || element == "graphicDesign" || element == "adMaterial" || element == "commercialFilming") {
          arr.push("visualDesign");
        } else if (element == "commercialClip" || element == "commercialVideo" || element == "animations") {
          arr.push("filming");
        } else if (element == "forum" || element == "blog" || element == "KOL" || element == "news") {
          arr.push("promote");
        } else {
          arr.push(element);
        }
      });
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
hr {
  width: 100%;
}
#case-view {
  width: 90vw;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  #back-to-top {
    position: fixed;
    cursor: pointer;
    left: 90%;
    bottom: 10%;
    @include font(large);
  }
  .caseType {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 8%;
    margin-bottom: 4%;
    .typeIcon {
      cursor: pointer;
      position: relative;
      display: flex; 
      justify-content: center;
      img:hover ~ span {
        opacity: 1;
        bottom: 120%;
      }
    }
    span {
      cursor: default;
      position: absolute;
      opacity: 0;
      transition: 0.3s;
      bottom: 80%;
      white-space: nowrap;
    }
    img {
      height: 60px;
      width: 60px;
      margin: 0 2vw;
      filter: brightness(0);

      &.active {
        filter: brightness(1);
      }
      @media #{$tablet} {
        height: 50px;
        width: 50px;
      }
      @media #{$phone} {
        height: 25px;
        width: 25px;
      }
    }
  }
  .brandStory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4%;
    .brandImage {
      width: 58%;
      img {
        width: 100%;
      }
    }
    .story {
      width: 38%;
      text-align: left;
      white-space: pre-wrap;
      h2 {
        margin-bottom: 2%;
        text-align: center;
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      .brandImage {
        width: 80%;
      }
      .story {
        width: 80%;
      }
    }
  }
  .vendors {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .vendor-logos {
      margin: 40px;
      width: 180px;
      height: 180px;
      @media #{$tablet} {
        margin: 10px;
        width: 90px;
        height: 90px;
      }
    }
  }
}
</style>
