<template>
  <div id="case-studies">
    <transition name="opaFadeIn">
    <loading v-if="isLoading" />
    </transition>
    <back v-if="chosenvendor!='' && chosenvendor" />
      <header>
        <top-title title="TRUST US" subTitle="我們的專業值得您信賴"></top-title>
      </header>
      <div id="innerNav">
        <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(1)">
          <span :class="{'active': caseType == ''}" @click="chooseCaseType('', $event)">全部</span>
        </div>
        <div
          class="navTopic"
          :class="{'active': hasTopic('website')}"
          @mouseover="navHov"
          @mouseout="navHovOut"
          @click="chooseNav(2)"
        >
          <span
            :class="{'active': caseType == 'website'}"
            @click="chooseCaseType('website', $event)"
          >網站製作</span>
          <div class="subNav">
            <span
              :class="{'active': caseType == 'productPromote'}"
              @click="chooseCaseType('productPromote', $event)"
            >產品宣傳</span>
            <span
              :class="{'active': caseType == 'brandImage'}"
              @click="chooseCaseType('brandImage', $event)"
            >品牌形象</span>
            <span
              :class="{'active': caseType == 'interactive'}"
              @click="chooseCaseType('interactive', $event)"
            >互動機制</span>
          </div>
        </div>
        <div
          class="navTopic"
          :class="{'active': hasTopic('mediaAds')}"
          @mouseover="navHov"
          @mouseout="navHovOut"
          @click="chooseNav(3)"
        >
          <span
            :class="{'active': caseType == 'mediaAds'}"
            @click="chooseCaseType('mediaAds', $event)"
            >媒體廣告投放</span>
          <div class="subNav">
            <span
              :class="{'active': caseType == 'facebook'}"
              @click="chooseCaseType('facebook', $event)"
            >Facebook</span>
            <span
              :class="{'active': caseType == 'google'}"
              @click="chooseCaseType('google', $event)"
            >Google</span>
            <span
              :class="{'active': caseType == 'line'}"
              @click="chooseCaseType('line', $event)"
            >LINE</span>
          </div>
        </div>
        <div
          class="navTopic"
          :class="{'active': hasTopic('visualDesign')}"
          @mouseover="navHov"
          @mouseout="navHovOut"
          @click="chooseNav(4)"
        >
          <span
            :class="{'active': caseType == 'visualDesign'}"
            @click="chooseCaseType('visualDesign', $event)"
          >視覺設計</span>
          <div class="subNav">
            <span
              :class="{'active': caseType == 'enterpriseBrand'}"
              @click="chooseCaseType('enterpriseBrand', $event)"
            >企業品牌</span>
            <span
              :class="{'active': caseType == 'graphicDesign'}"
              @click="chooseCaseType('graphicDesign', $event)"
            >平面設計</span>
            <span
              :class="{'active': caseType == 'adMaterial'}"
              @click="chooseCaseType('adMaterial', $event)"
            >廣告素材</span>
            <span
              :class="{'active': caseType == 'commercialFilming'}"
              @click="chooseCaseType('commercialFilming', $event)"
            >商業攝影</span>
          </div>
        </div>
        <div
          class="navTopic"
          :class="{'active': hasTopic('filming')}"
          @mouseover="navHov"
          @mouseout="navHovOut"
          @click="chooseNav(5)"
        >
          <span
            :class="{'active': caseType == 'filming'}"
            @click="chooseCaseType('filming', $event)"
          >影片製作</span>
          <div class="subNav">
            <span
              :class="{'active': caseType == 'commercialClip'}"
              @click="chooseCaseType('commercialClip', $event)"
            >商品短片類</span>
            <span
              :class="{'active': caseType == 'commercialVideo'}"
              @click="chooseCaseType('commercialVideo', $event)"
            >商業影片類</span>
            <span
              :class="{'active': caseType == 'animations'}"
              @click="chooseCaseType('animations', $event)"
            >動畫類</span>
          </div>
        </div>
        <div class="navTopic" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(6)">
          <span
            :class="{'active': caseType == 'socialMediaManagement'}"
            @click="chooseCaseType('socialMediaManagement', $event)"
          >自媒體經營</span>
        </div>
        <div
          class="navTopic"
          :class="{'active': hasTopic('promote')}"
          @mouseover="navHov"
          @mouseout="navHovOut"
          @click="chooseNav(7)"
        >
          <span
            :class="{'active': caseType == 'promote'}"
            @click="chooseCaseType('promote', $event)"
          >口碑宣傳</span>
          <div class="subNav">
            <span
              :class="{'active': caseType == 'forum'}"
              @click="chooseCaseType('forum', $event)"
            >討論區</span>
            <span
              :class="{'active': caseType == 'blog'}"
              @click="chooseCaseType('blog', $event)"
            >部落客</span>
            <span
              :class="{'active': caseType == 'KOL'}"
              @click="chooseCaseType('KOL', $event)"
            >KOL 網紅</span>
            <span
              :class="{'active': caseType == 'news'}"
              @click="chooseCaseType('news', $event)"
            >新聞稿</span>
          </div>
        </div>
        <hr id="navUnderLine">
      </div>
      <template v-if="chosenvendor=='' || !chosenvendor">
      <hr class="divide">
      <h3>我們的客戶</h3>
      <h3 class="light" style="margin-bottom:4%">Our clients</h3>
      <transition-group name="trans" tag="div" class="vendors">
        <template v-for="(vendor, idx) in vendors" >
        <div class="vendor-blocks" :key="idx"
          v-if="vendor.caseType.toString().includes(caseType)">
          <vendor-logos
            :vendorData="vendor"
            :key="idx"
          />
        </div>
        </template>
      </transition-group>
    </template>
    <case-view v-if="chosenvendor!='' && chosenvendor && vendorData.name && refresh" :vendorData="vendorData" :vendorName="chosenvendor" style="margin-top: 10%"/>

    <footer-view style="margin-top:4%;"/>
  </div>
</template>
<script>
import loading from "@/components/loading.vue"
import back from "@/components/back.vue";
import topTitle from "@/components/topTitle.vue";
import footerView from "@/components/footerView.vue";

import vendorLogos from "@/caseComponents/vendorLogos.vue";
import caseView from "@/caseComponents/caseView.vue";

export default {
  components: {
    loading,
    back,
    topTitle,
    vendorLogos,
    caseView,
    footerView
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    var vm = this;
    if (vm.$route.params.caseType) {
      vm.caseType = vm.$route.params.caseType;
    }
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_PHP + "ClientAPI/getLogo",
      data: {},
      dataType: "json",
      success: function(res) {
        vm.vendors = res;
        setTimeout(function() {
          vm.isLoading = false
        }, 700);
      }
    });
  },
  mounted() {
    this.$parent.currentPage = "CaseStudies";
    this.$parent.isLoading = false;
    this.navHovOut();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    chosenvendor() {
      var vm = this;
      var currentvendor = vm.$route.query.vendor;
      $("html, body").animate({ scrollTop: 0 }, 0);
      if (currentvendor) {
        vm.refresh = false;
        vm.vendors.forEach(el => {
          if (el.name == currentvendor) {
            vm.randomType = el.caseType[Math.floor(Math.random() * el.caseType.length)];
          }
        })
        $.ajax({
          type: "GET",
          url: process.env.VUE_APP_PHP + "ClientAPI/getData",
          data: {
            vendor: currentvendor,
            randomType: vm.randomType
          },
          dataType: "json",
          success: function(res) {
            vm.refresh = true;
            if (res.length == 0) {
              vm.$router.replace({name: "CaseStudies"});
            } else {
              vm.vendorData = res[0];
              if (vm.vendorData.contents.brandStoryImg == "") {
                vm.vendorData.contents.brandStoryImg = "brandStory.jpg";
              }
              setTimeout(function() {
                vm.isLoading = false
              }, 700);
            }
          }
        });
      }
      return currentvendor;
    },
    currentTopic() {
      var idx;
      if (["website", "productPromote", "brandImage", "interactive"].includes(this.caseType)) {
        idx = 2;
      } else if (["mediaAds", "facebook", "google", "line"].includes(this.caseType)) {
        idx = 3;
      } else if (["visualDesign", "enterpriseBrand", "graphicDesign", "adMaterial", "commercialFilming"].includes(this.caseType)) {
        idx = 4;
      } else if (["filming", "commercialClip", "commercialVideo", "animations"].includes(this.caseType)) {
        idx = 5;
      } else if (["socialMediaManagement"].includes(this.caseType)) {
        idx = 6;
      } else if (["promote", "forum", "blog", "KOL", "news"].includes(this.caseType)) {
        idx = 7;
      } else {
        idx = 1;
      }
      return idx;
    }
  },
  data() {
    return {
      isLoading: true,
      refresh: true,
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      vendorData: {},
      caseType: "",
      randomType: "",
      vendors: []
    };
  },
  methods: {
    navHov(e) {
      if($("#navUnderLine").length > 0) {
        $("#navUnderLine").width($(e.target).width() * 0.9);
        var left =
          $(e.target).offset().left -
          $("#innerNav").offset().left +
          ($(e.target).width() * (1 - 0.9)) / 2;
        $("#navUnderLine").css({ left: left + "px" });
      }
    },
    navHovOut() {
      if ($("#navUnderLine").length > 0) {
        $("#navUnderLine").css({
          left:
            $(".navTopic")[this.currentTopic - 1].offsetLeft +
            ($(".navTopic")[this.currentTopic - 1].clientWidth * (1 - 0.9)) / 2 +
            "px"
        });
        $("#navUnderLine").width(
          $(".navTopic")[this.currentTopic - 1].clientWidth * 0.9
        );
      }
    },
    handleResize() {
      if (this.chosenvendor == "" || !this.chosenvendor) {
        this.navHovOut();
      }
    },
    chooseNav(idx) {
      this.$router.replace({name: "CaseStudies"})
      $(".navTopic").removeClass("active");
      $(".navTopic").css({ color: "#707070" });
      $(".navTopic")[this.currentTopic - 1].style.color = "#73cccf";
      $(".navTopic")[this.currentTopic - 1].classList.add("active");
    },
    chooseCaseType(type, e) {
      this.caseType = type;
      $(".subNav span").removeClass("active");
      e.target.classList.add("active");
    },
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop;
      if (this.st > this.lastScrollTop) {
        $(".return").css({ transform: "translateX(-85%)" });
      } else {
        $(".return").css({ transform: "translateX(0)" });
      }
      this.lastScrollTop = this.st <= 0 ? 0 : this.st;
    },
    hasTopic(topic) {
      var topics;
      switch (topic) {
        case "website":
          topics = ["website", "productPromote", "brandImage", "interactive"];
          break;
        case "mediaAds":
          topics = ["mediaAds", "facebook", "google", "line"];
          break;
        case "visualDesign":
          topics = [
            "visualDesign",
            "enterpriseBrand",
            "graphicDesign",
            "adMaterial",
            "commercialFilming"
          ];
          break;
        case "filming":
          topics = [
            "filming",
            "commercialClip",
            "commercialVideo",
            "animations"
          ];
          break;
        case "socialMediaManagement":
          topics = ["socialMediaManagement"];
          break;
        case "promote":
          topics = ["promote", "forum", "blog", "KOL", "news"];
          break;
        default:
          break;
      }
      return topics.includes(this.caseType);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";


.trans-leave-active {
  position: absolute;
  z-index: -1;
}

.trans-enter-to,
.trans-leave {
  transform: scale(1);
  opacity: 1;
}

.trans-enter,
.trans-leave-to {
  transform: scale(0);
  opacity: 0;
}

header {
  background-image: url("../assets/image/caseStudies/topBanner.jpg");
}
#case-studies {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707070;
  .divide {
    margin: 120px 0 2% 0;
    background-color: #707070;
    width: 80%;
    height: 1px;
    @media #{$tablet} {
      margin: 0;
    }
  }
  #innerNav {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 4% 0;
    transform: translate(0) !important;
    span.active {
      color: $mainBlue;
    }
    .navTopic {
      cursor: pointer;
      margin: 0 3%;
      transition: 0.3s;
      font-size: 18px;
      font-weight: 300;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover,
      &.active {
        & > span {
          color: $mainBlue !important;
        }
        .subNav {
          transform: scale(1);
        }
        & ~ div {
          color: #707070 !important;
        }
      }
      .subNav {
        position: absolute;
        transition: .5s;
        top: 135%;
        color: #707070;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          padding: 4% 0;
          transition: 0.3s;
          &:hover,
          &.active {
            color: $mainBlue !important;
          }
        }
      }
    }
    #navUnderLine {
      position: absolute;
      margin: 0;
      height: 1px;
      background-color: $mainBlue;
      top: 100%;
      transition: 0.3s;
      @media #{$tablet} {
        display: none;
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      align-self: flex-start;
      align-items: flex-start;
      margin-left: 10vw;
      width: 30vw;
      .navTopic {
        text-align: left;
        margin: 2% 0;
        &.active {
          .subNav {
            animation: subNavGrow .6s;
          }
          span::after {
            content: "";
            display: block;
            width: 110%;
            height: 1px;
            background-color: $mainBlue;
            animation: underLineGrow .3s;
          }
        }
        .subNav {
          top: 30%;
          left: 140%;
          transform: scale(0, 1);
          transform-origin: 0 50%;
          justify-content: center;
          align-items: flex-start;
          & span {
            &::after{
              display: none;
            }
          }
        }
      }
    }
  }
  .vendors {
    width: 75vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    .vendor-blocks {
      $logoUnit: 230px;
      $logoUnitM: 131px;
      transition: .5s;
      margin: 15px;
      width: $logoUnit;
      height: $logoUnit;
      @media #{$tablet} {
        margin: 8px;
        width: $logoUnitM;
        height: $logoUnitM;
      }
    }
    @media #{$phone} {
      width: 95vw;
    }
    @media #{$slim} {
      width: 75vw;
    }
  }
}
</style>
