<template>
<transition name="opaFadeIn">
  <div class="preview-theater" :class="{'scrollable': scrollable, 'scale16-9': isYoutube}" @click="stopPreview">
    <slot></slot>
  </div>
</transition>
</template>

<script>
export default {
  props: {
    scrollable: false,
    isYoutube: false,
    isOrigin: false
  },
  mounted() {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  },
  destroyed() {
    document.getElementsByTagName("html")[0].style.overflow = null;
    document.getElementsByTagName("html")[0].style.overflowX = "hidden";
  },
  methods: {
    stopPreview() {
      this.$parent.isPreview = false;
    }
  }
};
</script>

<style lang="scss">
.preview-theater {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 13;
  background-color: #000000cc;
  display: flex;
  align-items: center;
  justify-content: center;
  & :nth-child(n) {
    max-width: 80vw;
    max-height: 90vh;
  }
  &.scrollable {
    display: block;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    & :nth-child(n) {
      margin: 10% 0;
      top: 0;
      max-height: none;
      max-width: 90vw;
    }
  }
  &.scale16-9 :nth-child(n) {
    max-width: 80vw;
    max-height: 45vw;
  }
}
</style>
