<template>
  <div class="video-frame-c">
    <div class="animation">
      <h2>{{source.title}}</h2>
      <template v-for="(img, idx) in source.src">
        <div class="image" :key="idx">
          <img class="playBtn" src="../assets/image/caseStudies/playBtn.svg" @click="showVid(img[1], img[2])">
          <img :src="`${vendorURL}vendors/${vendorName}/vids/${img[0]}`" @click="showVid(img[1], img[2])">
        </div>
      </template>
    </div>

    <preview-theater v-if="isPreview" :isYoutube="isYoutube" :isOrigin="isOrigin">
      <youtube v-if="isYoutube" :player-vars="playerVars" :video-id="videoSource" width="100%" height="100%" />
      <iframe v-if="isGoogleDrive" :src="`https://drive.google.com/file/d/${videoSource}/preview`" height="100%" width="100%"></iframe>
      <video v-if="isOrigin" :src="`${vendorURL}vendors/${vendorName}/vids/${videoSource}`" autoplay controls @click.stop="">
      </video>
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";

export default {
  components: {
    previewTheater
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      playing: false,
      playerVars: {
        autoplay: 1
      },
      videoSource: "",
      isYoutube: false,
      isGoogleDrive: false,
      isOrigin: false,
      isPreview: false
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.source.src.forEach(src => {
      src[1].split("#$#");
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
      // $(".YTrescale").each(el => {
      //   console.log(el)
      // });
    },
    showVid(sourceType, vidSource) {
      var vm = this;
      vm.videoSource = vidSource;
      switch (sourceType) {
        case "YT":
          vm.isYoutube = true;
          break;
        case "GD":
          vm.isGoogleDrive = true;
          break;
        default:
          vm.isOrigin = true;
          break;
      }
      vm.isPreview = true;
    },
    togglePlay(e) {
      this.playing ? e.target.pause() : e.target.play();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
.playBtn {
  color: #707070;
  position: absolute;
  width: 140px;
  height: 120px;
  top: calc(50% - 60px);
  left: calc(50% - 70px);
  z-index: 1;
}

.video-frame-c {
  h2 {
    margin: 4% 0;
  }
  img {
    cursor: pointer;
    width: 100%;
  }
  .image {
    margin: 2% 0;
    position: relative;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .playBtn {
      color: #707070;
      position: absolute;
      width: 140px;
      height: 120px;
      top: calc(50% - 60px);
      left: calc(50% - 70px);
      z-index: 1;
    }
  }
}
</style>
