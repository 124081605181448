<template>
  <div class="asset-frame-a">
    <h2>{{source.title}}</h2>
    <carousel :items="1" :nav="false">
    <div class="nine-frame" v-for="n in pages" :key="n">
      <div class="image" v-for="(img, idx) in source.src.slice((n-1) * 9, n * 9)" :key="idx">
        <img :src="`${vendorURL}vendors/${vendorName}/image/${img}`" @click="preview(img)">
      </div>
    </div>
    </carousel>
    <preview-theater v-if="isPreview">
      <img class="source" :src="`${vendorURL}vendors/${vendorName}/image/${openedImg}`">
    </preview-theater>
  </div>
</template>

<script>
import previewTheater from "@/caseComponents/previewTheater.vue";
import carousel from "vue-owl-carousel";

export default {
  components: {
    previewTheater,
    carousel
  },
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isPreview: false,
      openedImg: "",
      pages: 1
    };
  },
  beforeMount() {
    this.pages = (this.source.src.length - (this.source.src.length % 9)) / 9;
    this.source.src.length % 9 == 0 ? "" : this.pages++;
  },
  methods: {
    preview(img) {
      this.openedImg = img;
      this.isPreview = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.asset-frame-a {
  width: 100%;
  h2 {
    @include font(medium);
    margin: 2% 0;
  }
}
.nine-frame {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .image {
    cursor: pointer;
    width: 30%;
    margin: 1%;
    img {
      width: 100%;
    }
  }
}
</style>
