<template>
  <div class="article-frame-d">
    <h2>{{source.title}}</h2>
    <div class="container">
      <div class="article" v-for="(article, idx) in source.articles" :key="idx">
        <img :src="`${vendorURL}vendors/${vendorName}/article/${source.src[idx]}`">
        <h2>{{article.title}}</h2>
        <div class="content">
          {{article.content.trim()}}
        </div>
        <a :href="article.link" target="_blank">觀看更多</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.article-frame-d {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4% 0;
  h2 {
    @include font(medium);
    margin: 2% 0;
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
      flex-wrap: wrap;
    img {
      width: 100%;
    }
    .article {
      width: 28%;
      margin: 0 4%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media #{$tablet} {
        width: 100%;
        margin: 2% 0;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
      .content {
        white-space: pre-line;
        font-weight: 300;
        margin-bottom: 4%;
      }
      h2 {
        margin-top: 4%;
        @include font(small);
      }
      a {
        color: #ffffff;
        text-decoration: none;
        padding: 2% 8%;
        background-color: #707070;
        transition: .3s;
        &:hover {
          background-color: $mainRed;
        }
      }
    }
  }
}
</style>