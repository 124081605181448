<template>
  <div class="vendor-logos" :id="vendorData.name" @click="choosevendor(vendorData.name)">
    <div class="mask" :style="{'background-image':`url('${vendorURL}vendorLogo/${vendorData.logoImg}')`}"></div>
    <div class="underBar">{{vendorData.registerName}}</div>
  </div>
</template>

<script>
export default {
  props: {
    vendorData: Object
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL,
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false
      // vendorData: BY POST
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll() {
      // var st = window.pageYOffset || document.documentElement.scrollTop;
      // var hook = window.innerHeight;
      // if (
      //   st < this.$el.offsetTop - hook * 0.2 &&
      //   st > this.$el.offsetTop - hook * 0.6 &&
      //   this.isTablet
      // ) {
      //   this.$el.classList.add("active");
      // } else {
      //   this.$el.classList.remove("active");
      // }
    },
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
    },
    choosevendor(vendorName) {
      this.$router.push({ name: "CaseStudies", query: { vendor: vendorName }});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.vendor-logos {
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .mask {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
  .underBar {
    position: absolute;
    bottom: -10%;
    transition: 0.4s;
    opacity: 0;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: #fff;
    color: #000;
  }
  &:hover,
  &.active {
    .mask {
      filter: brightness(0.8) blur(1px);
    }
    .underBar {
      opacity: 1;
      bottom: 0;
    }
  }
}
</style>
