<template>
  <div id="dataGrow">
    <h2>{{source.title}}</h2>
    <div class="datas">
      <div class="data" :class="['d' + (idx + 1)]" v-for="(data, idx) in source.src" :key="idx">
        <h1>+ <span>{{data.grows}}</span> {{data.unit}}</h1>
        <h6>{{data.title}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

#dataGrow {
  width: 100%;
  margin-top: 4%;
  .datas {
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
    .data {
      @include font(medium);
      * {
        font-weight: 300;
      }
      &.d1 h1 {
        color: $mainRed;
      }
      &.d2 h1 {
        color: $mainPurple;
      }
      &.d3 h1 {
        color: $mainBlue;
      }
      h6 {
        @include font(small);
      }
    }
    @media #{$phone} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
