<template>
  <div class="article-frame-c">
    <h2>{{source.title}}</h2>
    <div class="container">
      <img :src="`${vendorURL}vendors/${vendorName}/article/${source.src}`">
      <div class="text">
        <h2>{{source.article.title}}</h2>
        <div class="content">
          {{source.article.content.trim()}}
        </div>
        <a :href="source.article.link" target="_blank">觀看更多</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.article-frame-c {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4% 0;
  h2 {
    @include font(medium);
    margin: 2% 0;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .content {
        white-space: pre-line;
        font-weight: 300;
        margin-bottom: 4%;
      }
      h2 {
        margin-top: 4%;
        @include font(small);
      }
    }
    a {
      color: #ffffff;
      text-decoration: none;
      padding: 2% 8%;
      background-color: #707070;
      transition: .3s;
      &:hover {
        background-color: $mainRed;
      }
    }
  }
  @media #{$phone} {
    width: 100%;
  }
}
</style>