<template>
  <div class="article-frame-b">
    <h2>{{source.title}}</h2>
    <div class="container">
      <img :src="`${vendorURL}vendors/${vendorName}/article/${source.src}`">
      <div class="text">
        <h2>{{source.article.title}}</h2>
        <div class="content">
          {{source.article.content}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    vendorName: String
  },
  data() {
    return {
      vendorURL: process.env.VUE_APP_VENDORURL
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.article-frame-b {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
  .container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 45%;
    }
    .text {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h2 {
        margin: 4% 0;
        @include font(medium);
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      justify-content: center;
      img, .text {
        width: 100%;
      }
    }
  }
}
</style>